<template>
  <div class="card homework rounded-3 border-1">
    <div class="card-body">
      <div class="row align-items-center justify-content-between flex-md-nowrap">
        <div class="cell col-md-2">{{ homework.mod_title }}</div>
        <div class="cell col-md-4 text-md-center">{{ homework.title }}</div>
        <div class="cell col-md-4 text-md-center">Due {{ formattedDueDate }}</div>
        <div class="cell col-md-2 action">
          <span v-if="homework.completed"><img v-svg-inline class="isvg img-fluid completed" src="../../../images/svg/check-circled.svg"></span>
          <a v-else-if="absoluteUrl" class="btn btn-sm btn-primary btn-rounded" :href="`/homeworks/${homework.id}`">
            <span v-if="homework.duration">Continue</span>
            <span v-else>Begin</span>
          </a>
          <router-link v-else :to="{name: 'homework', params: { homeworkId: homework.id }}" class="btn btn-sm btn-primary btn-rounded" @click="$emit('homeworkStarted')">
            <span v-if="homework.duration">Continue</span>
            <span v-else>Begin</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {format, isValid, parseISO} from 'date-fns';
import { computed} from 'vue';

const props = defineProps({
      homework: Object,
      absoluteUrl: Boolean
    });

// Computed
const formattedDueDate = computed(() => {
  let date = parseISO(props.homework?.due_date)
  if(isValid(date)){
    return format(date, 'EEEE do MMMM')
  } else {
    return "-"
  }
});
</script>
