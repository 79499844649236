import axios from 'axios';

import errorResponseHandler from './errorResponseHandler';

let token =  document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

if(token) {
  axios.defaults.xsrfHeaderName = "X-CSRF-Token";
  axios.defaults.headers.common["X-CSRF-Token"] = token;
} else {
  console.warn('CSRF token not found');
}

// apply interceptor on response
axios.interceptors.response.use(
  (response) => response,
  errorResponseHandler
);
