import axios from 'axios';

import notification from './notification';

function errorResponseHandler(error) {
  console.log(error);

  if(axios && axios.isCancel(error)) {
    console.log('Request canceled. ', error.message);
  // If errors array exist - reject error, component should handle form errors itself
  } else if(error.response?.data?.errors){
    return Promise.reject(error);
  } else if (!error.response) {
    notification.error("Could not connect to server. Please refresh the page or try again later.");
  } else if(error.response.status === 500){
    notification.error('Oops, something went wrong! Please refresh the page or try again later.');
  } else if(error.response.status === 401){
    notification.error("Your session has expired. Please relogin.");
    setTimeout(() => { window.location.reload(); }, 2000);
  } else if(error.response.status === 403){
    notification.error("Access denied.");
    setTimeout(() => { window.location.href = '/'; }, 1000);
  } else if (error.response?.data?.error?.message ) {
    notification.error(error.response.data.error.message);
  } else if (error.response.data?.message) {
    console.log(error.response.data?.message);
    notification.error(error.response.data.message);
  } else if(error.response.statusText){
    notification.error(`<strong>${error.response.statusText}</strong><br> Please refresh the page and try again.`);
  } else{
    notification.error('Oops, something went wrong! Please refresh the page or try again later.');
  }

  try{
    return Promise.reject(error);
  } catch (err){
  }
}

export default errorResponseHandler;
