import * as Sentry from "@sentry/vue";
import axios from 'axios';
import { defineStore } from 'pinia';

import CONST from '../common/constants';

export const useAppStore = defineStore('app', {
  state: () => ({
    loadings: 0, // current unfinished loading requests count
    appData: null, // contains data that used by app and need to be available on page load(before any user initiated events)
    user: null,// Current user info: name, role, access rights, urls
    userStats: null,
    homeworks: null
  }),
  getters: {
    /**
     * Get current user timezone
     * @return {*}
     */
    getUserTimezone(){
      return new Intl.DateTimeFormat('en-GB').resolvedOptions().timeZone;
    },
    /**
     * Get homeworks. Homeworks are sorted by due_date. Finished homeworks are shown in the end of the list
     * @return {*}
     */
    getHomeworks(){
      return this.homeworks?.sort((a, b) => {
        if(a.completed === b.completed){
          return (new Date(b.due_date) || 0) - (new Date(a.due_date) || 0)
        }
        return Number(a.completed) - Number(b.completed)
      });
    }
  },
  actions: {
    /**
     * fetch current user data
     * @returns {Promise<void>}
     */
    async fetchUser() {
      let {data} = await axios.get(`${CONST.API_PATH}/learner`);
      this.user = data;
      Sentry.setUser({
        id: String(data.id),
        name: `${data.first_name} ${data.last_name}`,
      });
    },

    /**
     * fetch current user statistics, like tokens, time, accuracy etc.
     * @returns {Promise<void>}
     */
    async fetchUserStats() {
      let {data} = await axios.get(`${CONST.API_PATH}/learner`);
      this.userStats = data;
    },

    /**
     * fetch current user homeworks
     * @returns {Promise<void>}
     */
    async fetchHomeworks() {
      let {data} = await axios.get(`${CONST.API_PATH}/homeworks`);
      this.homeworks = data;
    },

    /**
     * Increase or decrease loadings count
     * @param {Boolean} loading
     */
    setLoading(loading = true){
      if(loading){
        this.loadings++;
      } else{
        this.loadings = this.loadings - 1 < 0 ? 0 : this.loadings - 1; // count cant be less than 0;
      }
    },
  },
});
